<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="machine_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="65vh"
      fixed-header
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template v-slot:top>
        <v-toolbar flat>
 
        </v-toolbar>
        <v-alert dense text class="text-center" type="info">
          แสดง <strong>ทั้งหมด</strong> รวม <strong>{{row_count}}</strong> รายการ
        </v-alert>
      </template>

      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'pink darken-4': item.ID === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column"><v-layout justify-center>{{ item.MachineName }}</v-layout></td>
          <td><v-layout justify-center>{{ item.IPAddress }}</v-layout></td>
          <td><v-layout justify-center>{{ item.Code }}</v-layout></td>
          <td><v-layout justify-center>{{ item.Roomname }}</v-layout></td>
          <td><v-layout justify-center>{{ item.Round }}</v-layout></td>
          <td><v-layout justify-center>{{ item.ActiveDT }}</v-layout></td>
          <td><v-layout justify-center>{{Permission[item.Permission]}}</v-layout></td>
        </tr>
      </template>
    </v-data-table>

    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Machine/Popup_Menu";

export default {
  name: "Machine_Datatable",

  components: {
    PopupMenu,
  },
  created() {
    //โหลด ตาราง
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    machine_data() {
      //console.log("gettes - - >" + this.$store.getters["id/IDList"]);
      return this.$store.getters["machine/MachineList"];
    },
    row_count(){
        return this.$store.getters["machine/MachineList"].length;
    }
  },
  methods: {
    async fetch_table() {
      let credentials = {
        web_name: sessionStorage.getItem("WebName"),
      };
      this.$store.dispatch("machine/load_machine_list", credentials).then(
        (response) => {
          console.log(response.IDList);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selectedId = item.MachineName;
      console.log(item.id);
    },
    row_click: function(item) {
      this.selectedId = item.MachineName;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();

      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu1.Show(item.MachineName);
        this.selectedId = item.MachineName;
        //console.log(item);
      });
    },
  },
  data: () => ({
    selectedId: -1,

    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "ชื่อเครื่อง",
        value: "ID",
        align: "center",
        width: "150px",
      },
      { text: "IP", value: "PW", align: "center", width: "80px" },
      { text: "Code", value: "Credit", align: "center", width: "60px" },
      { text: "ห้องล่าสุด", value: "Credit", align: "center", width: "60px" },
      { text: "รอบล่าสุด", value: "Credit", align: "center", width: "60px" },
      { text: "เชื่อมต่อล่าสุด", value: "Credit", align: "center", width: "60px" },
      { text: "สถานะ", value: "Status", align: "center", width: "50px" },
    ],
    Permission: ["รอยืนยัน","ใช้งาน","ปฏิเสธให้เข้าใช้","หยุดแทง"],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: #880e4f !important;
  color: white;
  /* text-align: center !important;  
  padding-top: 10px !important; */
  /* position:absolute;  */
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: #880e4f !important;
  color: white !important;
  padding-top: 15px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: white;
  vertical-align: top;
  /* font-size: 1.0vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  /* font-size: 110% !important; */
}

::v-deep table tbody tr td {
  /* font-size: 1.5vw !important; */
  /* font-size: 1.4vmax !important; */
  font-size: 14px !important;
  /* font-size: 110% !important; */
}
/* /deep/ tr.v-data-table__selected {
    background: lightgray !important;
    color: black;
    font-weight: bold;
  } */
</style>
