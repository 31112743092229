<template>
  <v-dialog
    v-model="permission_dialog"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :hide-overlay="$vuetify.breakpoint.mdAndDown"
    persistent
    max-width="600px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="pink darken-4">
        <v-toolbar-title>การอนุญาติ</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click.native="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- start form -->

      <v-card class="px-4">
        <v-card-text>
          <v-radio-group v-model="OptPermission" column>
            <v-radio label="รออนุมัติ" value="0"></v-radio>
            <v-radio class="mt-2" label="อนุมัติ" value="1"></v-radio>
            <v-radio class="mt-2" label="ปฎิเสธ" value="2"></v-radio>
            <v-radio class="mt-2" label="หยุดทำงาน" value="3"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            ยกเลิก
          </v-btn>
          <v-btn color="blue darken-1" text @click="change_permission()">
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :color="prop_snackbar.color"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ error_text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
export default {
  name: "Edit",

  props: {
    //แสดง popup
    permission_dialog: {
      default: false,
    },
  },

  methods: {
    set_id(ID) {
      this.MachineName = ID;
      //load
    },
    change_permission() {
    //   console.log(this.OptPermission);
      this.do_Set_Permission();
    },


    async do_Set_Permission() {
      let credentials = {
        WebName: sessionStorage.getItem("WebName"),
        MachineName: this.MachineName,
        Permission: this.OptPermission,
      };

      await this.$store.dispatch("machine/set_permission", credentials).then(
        (response) => {
          //clear form
          //   this.$refs.form.reset();
          //   this.$refs.form.resetValidation();
          //   this.form.StatusItem = { n: "ใช้งาน", v: "01" };
          //   this.refID = response.ID;
          //   this.form.ID = "";
          //   this.form.PW = "";
          //   this.form.Credit = 100;
          //   this.$refs.form.resetValidation();
          //   this.$refs.txtID.focus();
            // this.$alert("สำเร็จ.", "แก้ไขเรียบร้อย", "success").then(() =>
            //   this.close()
            // );
          //   switch (response.Status) {
          //     case "0":
          //       this.$router.push("/Dashboard");
          //       break;
          //   }
          //this.$router.push("/Dashboard");
          //console.log(response);
        },
        (error) => {
          switch (error) {
            case "ID_DUPLICATE":
              this.$alert("ไอดีซ้ำ.", "ผิดพลาด", "error").then(() =>
                this.$refs.txtID.focus()
              );
              break;
          }
          //console.log("error");
        }
      );
    }, //end send to server
    async do_GetPermission(MachineName) {
      let credentials = {
        WebName: sessionStorage.getItem("WebName"),
        MachineName: MachineName,
      };

      await this.$store.dispatch("machine/get_permission", credentials).then(
        (response) => {
        //   console.log(response);
          this.OptPermission = response;
        },
        (error) => {
          console.log(error);
        }
      );
    }, //end send to server
    close() {
      this.$emit("update:permission_dialog", false);
    },
    // show_error(error_text) {
    //   this.prop_snackbar.color = "error";
    //   this.error_text = error_text;
    //   this.snackbar = true;
    // },
  },
  data: () => ({
    notifications: false,
    sound: true,
    widgets: false,
    // form
    valid: true,

    MachineName: "",

    OptPermission: null,

    //snackbar
    snackbar: false,
    prop_snackbar: {
      color: "error",
    },
    mode: "",
    error_text: "My timeout is set to 5000.",
    timeout: 5000,
    x: null,
    y: "top",
  }),
  watch: {
    permission_dialog(val) {
      if (val) {
        setTimeout(() => {
          // read data
          this.do_GetPermission(this.MachineName);
        });
      }
    },
  },
};
</script>

<style></style>
